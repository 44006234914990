import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import { ReactComponent as ScraperAPIIllustration3 } from "assets/images/scraperapi-illustration3.svg";

import { cx, fmtDate, fmtNumber } from "utils";
import { isAnnualPlan, isEnterprise } from "utils/planUtils";

import { RequiredUserContextType, UserContextType, useUserProvider } from "providers/UserProvider";
import { useChargebeePortals } from "providers/ChargebeePortalProvider";
import { useApiCalls } from "providers/ApiCallsProvider";

import StatefulLink from "components/StatefulLink";
import TaggedText from "components/TaggedText";
import Spinner from "components/Spinner";

import { useUser } from "routes/dataroutes/UserData";

import CancelSubscriptionLinks from "./CancelSubscriptionLinks";


type NonNullableSubscription = NonNullable<UserContextType["subscription"]>;

export default function SubscriptionManagementCard(
  {
    setApiCallInProgress
  }: {
    setApiCallInProgress?: () => void;
  }
) {
  const user = useUser()!;
  const { subscription } = useUserProvider() as RequiredUserContextType;
  const location = useLocation();
  const { openChargebeePortal } = useChargebeePortals();
  const myPlanId = subscription?.plan_id;
  const isAnnual = isAnnualPlan(myPlanId);
  const isUpdating = useMemo(() => {
    return !!subscription?.last_renewal_attempt ||
      (user && subscription && user.planSlug !== subscription.plan_id);
    },
    [ user, subscription ]
  );

  const { inProgress: apiCallInProgress } = useApiCalls();

  const [ checkingBillingInfoFor, setCheckingBillingInfoFor ] = useState<"manage_coupons" | undefined>(undefined);

  useEffect(() => {
      if (!apiCallInProgress) {
        setCheckingBillingInfoFor(undefined);
      }
    },
    [ apiCallInProgress, setCheckingBillingInfoFor ]
  );

  useEffect(() => {
      if (checkingBillingInfoFor) {
        setApiCallInProgress?.();
      }
    },
    [ checkingBillingInfoFor, setApiCallInProgress ]
  );

  const SubscriptionStatus = useCallback(
    ({
       isUpdating = false,
       has_scheduled_changes,
       scheduledSubscription,
       current_term_end,
       next_renewal_at,
       auto_renewal,
       has_payment_issues
     }: {
      isUpdating?: boolean;
      has_scheduled_changes: NonNullableSubscription["has_scheduled_changes"];
      scheduledSubscription: NonNullableSubscription["scheduledSubscription"];
      current_term_end: NonNullableSubscription["current_term_end"];
      next_renewal_at: NonNullableSubscription["next_renewal_at"];
      auto_renewal: number | undefined;
      has_payment_issues: boolean;
    }) => {
      if (isUpdating) {
        return <>Your billing information is being updated.</>;
      }

      const addAutoRenewalAndPaymentIssuesMessages = (statusMessageParts: ReactElement[], auto_renewal: number | undefined, has_payment_issues: boolean) => {
        if (auto_renewal) {
          if (has_payment_issues) {
            statusMessageParts.push(<span key="payment issues">. The change will <b>not</b> happen automatically after reaching {auto_renewal}% of your credits because you have outstanding invoices.<br/></span>);
          } else {
            statusMessageParts.push(<span key="auto renewal"> or after reaching {auto_renewal}% of your credits.<br/></span>);
          }
        } else {
          statusMessageParts.push(<span key="no auto renewal">.<br/></span>);
        }
      };


      let statusMessageParts = [];

      const lastMonthOfAnnual = isAnnual && (next_renewal_at === current_term_end);

      if (isAnnual) {

        statusMessageParts.push(<span key="annual reset">Your credits will be reset</span>);

        if (lastMonthOfAnnual) {
          statusMessageParts.push(<span key="annual last month"> and </span>);
        } else {
          statusMessageParts.push(<span key="annual reset date"> on {fmtDate(new Date(next_renewal_at * 1000))}</span>);
          addAutoRenewalAndPaymentIssuesMessages(statusMessageParts, auto_renewal, has_payment_issues);
        }
      }

      const subscription = isAnnual ? (lastMonthOfAnnual ? "your annual" : "Your annual") : "Your";

      if (has_scheduled_changes) {
        if (scheduledSubscription?.plan_id === "free") {
          statusMessageParts.push(<span key="cancellation">{subscription} subscription will {lastMonthOfAnnual ? "also" : ""} be cancelled on </span>);
        } else {
          statusMessageParts.push(<span key="scheduled change">{subscription} subscription is scheduled to change to the {scheduledSubscription?.name} plan on </span>);
        }
      } else {
        statusMessageParts.push(<span key="annual renewal">{subscription} subscription will {lastMonthOfAnnual ? "also" : ""} be renewed on </span>);
      }
      statusMessageParts.push(<span key="renewal date">{fmtDate(new Date(current_term_end * 1000))}</span>);

      if (!isAnnual || lastMonthOfAnnual) {
        addAutoRenewalAndPaymentIssuesMessages(statusMessageParts, auto_renewal, has_payment_issues);
      } else {
        statusMessageParts.push(<span key="trailing dot">.</span>);
      }

      return <>{statusMessageParts}</>;
    },
    [ isAnnual ]
  );

  return (
    <div
      className="grid lg:grid-cols-lgSubscriptionCard px-12 py-6 bg-white border border-borderColor gap-x-16 gap-y-8"
    >
      <div className="flex flex-col items-center md:flex-row gap-x-16">
        <ScraperAPIIllustration3 className="flex-shrink-0 h-40 rounded-md w-28"/>
        <div className="flex flex-col items-center justify-center space-y-2 text-center md:items-start md:text-left">
          {user.isBlocked && (
            <span className="text-lg text-brandDarkest">
              <TaggedText message="Account blocked. [Contact support|contact_support]."/>
            </span>
          )}
          {!user.isBlocked && (
            <>
              <span className="text-lg text-brandDarkest">
                You are currently on the <b>{user.planName}</b> plan
              </span>
              <div className="text-sm text-gray">
                <>
                  You have used {fmtNumber(user.requestCount)} of {fmtNumber(user.apiCallLimit)}{" "}
                  successful credits this month on the {user.planName} plan.
                  <br/>
                </>
                <SubscriptionStatus
                  isUpdating={isUpdating}
                  has_scheduled_changes={
                    subscription?.has_scheduled_changes ?? false
                  }
                  scheduledSubscription={subscription?.scheduledSubscription}
                  current_term_end={subscription?.current_term_end ?? 0}
                  next_renewal_at={subscription?.next_renewal_at ?? 0}
                  auto_renewal={user.planAutoRenewal}
                  has_payment_issues={user.hasPaymentIssues}
                />
              </div>
            </>
          )}
        </div>
      </div>
      <div
        className="flex flex-col justify-center items-center lg:items-start pt-8 lg:pt-0 lg:pl-12 text-xs lg:text-left whitespace-nowrap gap-4 border-borderColor border-t lg:border-t-0 lg:border-l"
      >
        <StatefulLink
          onClick={() => openChargebeePortal("ADDRESS")}
          state={{ backgroundLocation: location }}
          className={user.isBlocked ? "" : "cursor-pointer hover:underline"}
          disabled={user.isBlocked && user.blockingCode !== "banned_from_free"}
        >
          <>Edit billing address</>
        </StatefulLink>
        <StatefulLink
          onClick={() => openChargebeePortal("PAYMENT_SOURCES")}
          state={{ backgroundLocation: location }}
          className={user.isBlocked ? "" : "cursor-pointer hover:underline"}
          disabled={user.isBlocked && user.blockingCode !== "banned_from_free"}
        >
          <>Edit payment method</>
        </StatefulLink>
        <StatefulLink
          onClick={() => openChargebeePortal("BILLING_HISTORY")}
          state={{ backgroundLocation: location }}
          className={user.isBlocked ? "" : "cursor-pointer hover:underline"}
          disabled={user.isBlocked}
        >
          <>View billing history</>
        </StatefulLink>
        <StatefulLink
          onClick={() => openChargebeePortal("SUBSCRIPTION_DETAILS")}
          state={{ backgroundLocation: location }}
          className={user.isBlocked ? "" : "cursor-pointer hover:underline"}
          disabled={user.isBlocked}
        >
          <>Manage subscription</>
        </StatefulLink>
        { (user.canUseAllCoupons || (user.canUseCoupons && !isAnnual)) && (
          <StatefulLink
            to="/coupons"
            onClick={() => setCheckingBillingInfoFor("manage_coupons")}
            state={{ backgroundLocation: location }}
            className={cx("flex gap-2", user.hasPaymentIssues || user.isBlocked || apiCallInProgress ? "" : "cursor-pointer hover:underline")}
            disabled={user.hasPaymentIssues || user.isBlocked || apiCallInProgress}
          >
            <>Manage coupons</>
            {(checkingBillingInfoFor === "manage_coupons" && apiCallInProgress) && (
              <Spinner className="w-4 h-4 animate-spin"/>
            )}
          </StatefulLink>
        )}
        {subscription?.plan_id && (subscription?.plan_id !== "free") && (
          <CancelSubscriptionLinks />
        )}
        {subscription?.plan_id && !isEnterprise(subscription.plan_id) && (
          <StatefulLink
            to="/delete-account"
            addLocationToState
            className={user.hasPaymentIssues || user.isBlocked || apiCallInProgress ? "" : "cursor-pointer hover:underline"}
            disabled={user.hasPaymentIssues || user.isBlocked || apiCallInProgress}
          >
            <>Request account deletion</>
          </StatefulLink>
        )}
      </div>
    </div>
  );
};

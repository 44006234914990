import { MouseEventHandler, ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";

import { cx } from "utils";


interface StatefulLinkProps {
  to?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  id?: string;
  state?: object;
  className: string;
  hoverClassName?: string;
  disabled?: boolean;
  children: ReactNode;
  addLocationToState?: boolean;
}


export default function StatefulLink(
  {
    to = "",
    onClick,
    id,
    state,
    className,
    hoverClassName,
    disabled = false,
    children,
    addLocationToState = false
  }: StatefulLinkProps
) {

  const location = useLocation();

  return (
    <>
      {disabled && (
        <span className={cx(className, "!cursor-default opacity-30")} id={id}>
          {children}
        </span>
      )}

      {!disabled && (
        <Link
          to={to}
          onClick={onClick}
          className={cx(className, hoverClassName)}
          id={id}
          state={{
            backgroundLocation: addLocationToState ? location : undefined,
            ...state
        }}
        >
          {children}
        </Link>
      )}
    </>
  );

};

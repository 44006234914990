import { useEffect } from "react";
import { useFetcher } from "react-router-dom";

import Spinner from "components/Spinner";
import StatefulLink from "components/StatefulLink";
import TaggedText from "components/TaggedText";
import { PureTooltip } from "components/Tooltip";

import { RequiredUserContextType, useUserProvider } from "providers/UserProvider";
import { useApiCalls } from "providers/ApiCallsProvider";

import { useUser } from "routes/dataroutes/UserData";

import Messages from "misc/Messages";

import { hasToContactUsBeforeChange } from "utils/planUtils";


export default function CancelSubscriptionLinks() {
  const { subscription } = useUserProvider() as RequiredUserContextType;
  const user = useUser()!
  const { inProgress: apiCallInProgress } = useApiCalls();
  const unpaidInvoicesFetcher = useFetcher();

  useEffect(() => {
    if ((unpaidInvoicesFetcher.state === "idle") && !unpaidInvoicesFetcher.data) {
      unpaidInvoicesFetcher.load("/billing-data/invoices/not-paid");
    }
  });

  const hasUnpaidInvoices = (unpaidInvoicesFetcher.data?.count !== undefined) && (unpaidInvoicesFetcher.data.count > 0);


  if (subscription?.scheduledSubscription?.plan_id === "free") {
    return (
      <StatefulLink
        to="/keep-subscription"
        addLocationToState
        className="cursor-pointer"
        hoverClassName="hover:underline"
        disabled={ user.isBlocked || apiCallInProgress }
      >
        Reactivate subscription
      </StatefulLink>
    );
  } else {
    return (
      <PureTooltip
        content={ hasUnpaidInvoices ? <TaggedText className="text-wrap" linkStyle="text-white hover:text-lightGray" message={ Messages.cannotCancelBecauseOfUnpaidInvoicesMessage } /> : undefined }
        clickable
      >
        <StatefulLink
          to={ hasToContactUsBeforeChange(subscription?.plan_id)
            ? "/cancel-enterprise"
            : "/cancel-subscription"
          }
          addLocationToState
          className="cursor-pointer"
          hoverClassName="hover:underline"
          disabled={
            user.hasPaymentIssues
            || user.isBlocked
            || apiCallInProgress
            || unpaidInvoicesFetcher.state === "loading"
            || hasUnpaidInvoices
          }
        >
          <div className="flex flex-row gap-x-2">
            <p>Cancel subscription</p>
            { unpaidInvoicesFetcher.state !== "idle" && <Spinner className="w-3.5 h-3.5 animate-spin" /> }
          </div>
        </StatefulLink>
      </PureTooltip>
    );
  }
};

import type { CollectorType } from 'providers/HostedScrapingProvider';

import { iconType, projectTypeToTitleAndDescription } from "sdecontent";
import { projectIcon } from "components/hosted-scraping/ProjectIcon";
import { cx } from 'utils';
import { ProjectVariant } from 'sdecontent';

export const ProjectDetailsHead = ({variant, configType}: {variant: ProjectVariant, configType: CollectorType}) => {
  const Icon = projectIcon(iconType(variant, configType));
  const { title, description } = projectTypeToTitleAndDescription(variant, configType);
  const iconBackground = configType === 'async_urls' ? 'bg-[#1a22e4]/[0.08]' : '';
  const iconStyle = cx("w-14 h-14 p-2 shrink-0 border border-solid mr-4 border-lightgray-600 flex items-center justify-center", iconBackground);
  return (
  <div className="h-14 w-full flex flex-row items-center">
    <div className={iconStyle}>
      <div className="h-7 flex items-center justify-center"> <Icon/></div>
    </div>
    <div className="shrink">
      <div className="text-lg font-semibold text-brandDarkest">{title}</div>
      <div className="text-sm font-normal text-gray-600">{description}</div>
    </div>
  </div>);
}
